<template>
  <div class="misc-inner">
    <div class="w-100 text-center">
      <logo class="mb-2" />
      <h2 class="mb-1">
        {{ $t('NOT_AUTHORIZED.TITLE') }} 🔐
      </h2>
      <p class="mb-2">
        {{ $t('NOT_AUTHORIZED.TEXT') }}
      </p>
      <b-img
        fluid
        :src="imgUrl"
        alt="Not authorized page"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue'
import Logo from '@/views/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    BLink, BImg, BButton, Logo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
